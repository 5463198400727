import React from "react"
import FMDGrand from "../components/FMDGrand"
import { Layout } from "../components/Layout"

const FMDGrandPage = () => {
  return (
    <Layout>
      <FMDGrand />
    </Layout>
  )
}

export default FMDGrandPage
