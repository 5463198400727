import React from "react"
import styled from "styled-components"

import { Row, Col } from "react-styled-flexboxgrid"
import { Primary, Secondary } from "../Section"
import { HrefButtonInverse } from "../Button"
import FMDGrandHero from "./FMDGrandHero"
import CoparentingHero from "./CoparentingHero"

import SvgImage from "../SvgImage"
import { BackgroundSectionStyle } from "../Section/backgroundSection.css.js"
import LogoFMD from "../../images/logo-fmd.svg"
import LogoMJMD from "../../images/logo-mjmd-mediation.svg"
import ImgBook from "../../images/fmd-book.png"

const StyledFMDGrandHero = styled(FMDGrandHero)`
  ${BackgroundSectionStyle};
  padding-top: 10rem;
  min-height: ${props => props.backgroundHeight}px;
  text-align: ${props => (props.centered ? "center" : "initial")};
`
const StyledCoParenting = styled(CoparentingHero)`
  ${BackgroundSectionStyle};
  min-height: ${props => props.backgroundHeight}px;
  text-align: ${props => (props.centered ? "center" : "initial")};
`

const FMDGrand = ({ sectionImg }) => {
  return (
    <section>
      <StyledFMDGrandHero backgroundHeight={550} centered>
        <h1>Forever My Daddy Grand Foundation</h1>
        <h2>Co-Parenting Rules!</h2>
      </StyledFMDGrandHero>
      <Primary>
        <Row>
          <Col xs={12} md={6}>
            <SvgImage
              width={350}
              imgSrc={LogoFMD}
              altText="The Joyner Hand Logo"
            />
          </Col>
          <Col xs={12} md={6}>
            <h3>
              OUR CORE BELIEFS AND MOST IMPORTANT VALUES INCLUDE CO-PARENTING
              WITHOUT PARENTAL ALIENTATION
            </h3>
            <p>
              Forever My Daddy Grand Foundation was born out of a strong desire
              to bring families who have suffered the effects of a broken
              marriage closer together. As a true advocate for the well-being of
              children who come from a broken home, Dr. Michael S. Joyner has
              written books, given lectures and now promotes an important
              initiative highlighting the significance of co-parenting.
            </p>
            <HrefButtonInverse href="http://www.fmdgrand.org/">
              Take me to FMD Grand Foundation
            </HrefButtonInverse>
          </Col>
        </Row>
      </Primary>
      <Secondary>
        <Row>
          <Col xs={12} md={6}>
            <SvgImage
              width={350}
              imgSrc={ImgBook}
              altText="FMD Grand Foundation logo"
            />
          </Col>
          <Col xs={12} md={6}>
            <h3>FOREVER MY DADDY: DENIED</h3>
            <p>
              Forever My Daddy: Denied is about Money, Power, and Legal Lessons
              Learned by a Daddy Blocked from his Children's Lives. Despite our
              modern epidemic of fatherlessness - and the myriad social problems
              it causes -- vast numbers of loving, caring, devoted fathers are
              blocked from their children's hearts and lives. It's called
              Parental Alienation Syndrome - a byproduct of broken marriages and
              a scourge of unnecessary emotional pain for kids and fathers
              alike, but it doesn't have to be this bad. There are powerful ways
              to cope.
            </p>
            <HrefButtonInverse href="http://www.forevermydaddy.com/">
              Take me to the FMD Denied Author’s page
            </HrefButtonInverse>
          </Col>
        </Row>
      </Secondary>
      <Primary>
        <Row>
          <Col xs={12} md={6}>
            <SvgImage
              width={350}
              imgSrc={LogoMJMD}
              altText="MJMD Mediation Logo"
            />
          </Col>
          <Col xs={12} md={6}>
            <h3>MEDIATION</h3>
            <p>
              MJ MD Mediation and Coaching Corporation provide mediation
              services in order to help save you time, money and potential
              wasted energy by avoiding some time in the courtroom while
              promoting a co-parenting spirit. Yes, you are a “targeted" high
              profile individual. And you should be! You are a special person,
              parent and/or hopeful parent who believes in children first.
              Therefore, whether before marriage, during a marriage or in the
              event of an unfortunate broken marriage you should protect and
              promote a co-parenting atmosphere.“Dr. J” and his team at MJ MD
              Mediation & Coaching will help you accomplish this!
            </p>
            <blockquote>
              “Whatever you would have your children become, strive to exhibit
              in your own lives and conversation.” - Lydia H. Sigourney
            </blockquote>
            <HrefButtonInverse href="https://www.mjmdcoaching.com">
              Take me to MJ MD Coaching
            </HrefButtonInverse>
          </Col>
        </Row>
      </Primary>
      <StyledCoParenting backgroundHeight={600} centered>
        <h1>
          Capturing Relationships and Celebrating Fathers and Grandparents as We
          Promote Co-Parenting!
        </h1>
      </StyledCoParenting>
    </section>
  )
}

export default FMDGrand
